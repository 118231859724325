import { FunctionComponent, createContext, useReducer } from "react";
import { parse } from "query-string";
import { Alternative } from "@lysaab/ui-2";
import {
  User,
  Question,
  RegisteredOwner,
  CONTROLLING_SHARE,
  CONTROL_TYPES,
  SuggestedOwner,
} from "../data/signup";
import { Company, COMPANY_VALIDATION_REASON } from "../data/bankid";
import { ALTERNATIVE_TYPE } from "../components/Question";

export type State = {
  campaign?: string;
  companies: Company[];
  companySelectionClickedId: string;
  companySelectionTaxResidencyConfirmed: boolean;
  companySelectionIsFatcaConfirmed: boolean;
  companyKyc?: Question[];
  accountKyc?: Question[];
  selectedCompany?: Company;
  registeredOwners: RegisteredOwner[];
  nonFinancialPassive?: Alternative<boolean>;
  users: User[];
  signupId?: string;
  experience?: Alternative<boolean>;
  volatility?: Alternative<boolean>;
  experienceAgreement?: boolean;
  volatilityAgreement?: boolean;
  email: string;
  ownMoney?: Alternative<boolean>;
  situationLiquidity?: Alternative<"more" | "less">;
  situationRisk?: Alternative<"yes" | "no">;
  allocationSelectedRisk?: number;
  allocationInvestment: string;
  allocationMonthlyInvestment: string;
  detailsEmail?: string;
  detailsBankAccount?: string;
  detalsPhoneNumber?: string;
  detailsTermsCheckbox?: boolean;
  designatedOwner?: SuggestedOwner;
  completedPep: number[];
  accountName: string;
};

const populatedState: State = {
  signupId: "0d694f09-23a7-49a1-9264-787723a521c5",
  selectedCompany: {
    companyId: "R38831Z",
    disabled: false,
    name: "ViGörVadViVill",
  },
  companies: [
    {
      name: "Gyllene Skor AB",
      companyId: "HERREYS-1984",
      disabled: false,
    },
    {
      name: "Kevin Mitnick LLC",
      companyId: "1337-H4X0R",
      disabled: false,
    },
    {
      name: "Evil corp",
      companyId: "666",
      disabled: true,
      reason: COMPANY_VALIDATION_REASON.NOT_AVAILABLE,
    },
    {
      name: "ViGörVadViVill",
      companyId: "R38831Z",
      disabled: false,
    },
  ],
  companySelectionClickedId: "",
  companySelectionTaxResidencyConfirmed: false,
  companySelectionIsFatcaConfirmed: false,
  detailsEmail: "populated@email.com",
  detailsBankAccount: "33008512121214",
  registeredOwners: [
    {
      id: 1,
      name: "Test Testsson",
      controllingShare: CONTROLLING_SHARE.SHARES_50_75,
      controlTypes: [CONTROL_TYPES.ART_10],
      controllingCompanies: undefined,
      pep: undefined,
      taxDomicile: "SE",
      citizenship: "SE",
    },
    {
      id: 2,
      name: "Berit Larsson",
      controllingShare: CONTROLLING_SHARE.SHARES_50_75,
      controlTypes: [CONTROL_TYPES.ART_10],
      controllingCompanies: undefined,
      taxDomicile: "SE",
      citizenship: "SE",
    },
  ],
  users: [
    {
      email: "admin@example.com",
      admin: true,
      identificationNumber: "197105266576",
      name: "Test Testsson",
    },
    {
      email: "readonly@example.com",
      admin: false,
      identificationNumber: "197104284844",
      name: "May Hallman",
    },
  ],
  email: "foo@bar.com",
  allocationInvestment: "10000",
  allocationMonthlyInvestment: "2000",
  allocationSelectedRisk: 90,
  ownMoney: {
    text: "Ja",
    value: true,
  },
  situationLiquidity: {
    text: "Mer än 6 månader",
    value: "more",
  },
  situationRisk: {
    text: "Nej",
    value: "no",
  },
  accountKyc: [
    {
      type: ALTERNATIVE_TYPE.CHECKBOX,
      id: "fef10828-409d-47c7-8acf-2a6c58bac633",
      question: "Vad är syftet med ditt Lysakonto?",
      alternatives: [
        { checked: true, label: "Ekonomisk trygghet", value: "0" },
        { checked: false, label: "Pension", value: "1" },
        { checked: false, label: "Konsumtion", value: "2" },
        { checked: false, label: "Gåva till närstående", value: "3" },
        { checked: false, label: "Långsiktig investering", value: "4" },
      ],
    },
    {
      type: ALTERNATIVE_TYPE.RADIO,
      id: "1fd066a1-66f1-41c9-8dd7-ac6bf5cb899d",
      question: "Insättningar ska göras...",
      alternatives: [
        { checked: true, label: "Månadsvis", value: "0" },
        { checked: false, label: "Flera gånger årligen", value: "1" },
        { checked: false, label: "En gång om året", value: "2" },
        {
          checked: false,
          label: "Endast vid ett fåtal tillfällen/engångsinsättning",
          value: "3",
        },
      ],
    },
  ],
  companyKyc: [
    {
      type: ALTERNATIVE_TYPE.CHECKBOX,
      id: "fef10828-409d-47c7-8acf-2a6c58bac633",
      question: "Vad är syftet med ditt Lysakonto?",
      alternatives: [
        { checked: true, label: "Ekonomisk trygghet", value: "0" },
        { checked: false, label: "Pension", value: "1" },
        { checked: false, label: "Konsumtion", value: "2" },
        { checked: false, label: "Gåva till närstående", value: "3" },
        { checked: false, label: "Långsiktig investering", value: "4" },
      ],
    },
    {
      type: ALTERNATIVE_TYPE.RADIO,
      id: "1fd066a1-66f1-41c9-8dd7-ac6bf5cb899d",
      question: "Insättningar ska göras...",
      alternatives: [
        { checked: true, label: "Månadsvis", value: "0" },
        { checked: false, label: "Flera gånger årligen", value: "1" },
        { checked: false, label: "En gång om året", value: "2" },
        {
          checked: false,
          label: "Endast vid ett fåtal tillfällen/engångsinsättning",
          value: "3",
        },
      ],
    },
  ],
  completedPep: [],
  accountName: "Värdepappersdepå",
};

const defaultState: State = {
  companies: [],
  companySelectionClickedId: "",
  companySelectionTaxResidencyConfirmed: false,
  companySelectionIsFatcaConfirmed: false,
  registeredOwners: [],
  users: [],
  email: "",
  allocationInvestment: "10000",
  allocationMonthlyInvestment: "2000",
  completedPep: [],
  accountName: "Värdepappersdepå",
};

interface CompanySignupContextValue {
  state: State;
  setState(stateUpdate: Partial<State> | ((oldState: State) => State)): void;
  clearContext: () => void;
}

export const CompanySignupContext = createContext(
  {} as CompanySignupContextValue
);

function reducer(
  oldState: State,
  stateUpdate: Partial<State> | ((oldState: State) => State)
) {
  if (typeof stateUpdate === "function") {
    return stateUpdate(oldState);
  } else {
    return { ...oldState, ...stateUpdate } as State;
  }
}

interface CompanySignupContextProviderProps {
  initialState?: Partial<State>;
}
export const CompanySignupContextProvider: FunctionComponent<
  React.PropsWithChildren<CompanySignupContextProviderProps>
> = ({ children, initialState }) => {
  let stateToUse = initialState
    ? { ...defaultState, ...initialState }
    : defaultState;

  if (parse(window.location.search).populate) {
    stateToUse = populatedState;
  }

  const [state, setState] = useReducer(reducer, stateToUse);
  const clearContext = () => {
    setState(() => defaultState);
  };
  return (
    <CompanySignupContext.Provider
      value={{
        state,
        setState,
        clearContext,
      }}
    >
      {children}
    </CompanySignupContext.Provider>
  );
};
