import { useState, useEffect, useContext } from "react";
import { CompanySignupContext } from "../../../models/CompanySignupContextProvider";
import { GetFeesResponse, getFees } from "../../../data/fees";

import "./EditAllocationFees.scss";
import { MoneyInput, Slider, useDebounceValue } from "@lysaab/ui-2";
import { MAX_INVESTMENT, MIN_INVESTMENT } from "./EditAllocation";
import { useAdvice } from "../../../models/AdviceContext";
import { simulateFutureWorthAndFee } from "./feeHelpers";
import { FormattedPercentage } from "./FormattedPercentage";
import { Amount } from "./Amount";

const FEE_DESCRIPTION_ELEVIO_ARTICLE_ID = "334";

interface Props {
  chosenAllocation: string;
}

const DEBOUNCE_TIMER = 500;

const INITIAL_FEES = {
  future: {
    discretionary: 0,
    fundManagement: 0,
    fundAssets: 0,
    transactionFees: 0,
    total: 0,
  },
  cost: {
    discretionary: 0,
    fundManagement: 0,
    fundAssets: 0,
    transactionFees: 0,
    total: 0,
  },
};

export const EditAllocationFees = ({ chosenAllocation }: Props) => {
  const { state: signupContextState } = useContext(CompanySignupContext);
  const advice = useAdvice();
  const [estimatedFees, setEstimatedFees] =
    useState<GetFeesResponse>(INITIAL_FEES);

  const [monthly, setMonthly] = useState(2000);
  const [horizon, setHorizon] = useState(5);
  const [expectedYield, setExpectedYield] = useState(7);

  const risk = parseInt(chosenAllocation, 10);
  const debouncedRisk = useDebounceValue(risk, DEBOUNCE_TIMER);

  const investmentType = advice.result.investmentType;
  const allocationInvestment = signupContextState.allocationInvestment;

  useEffect(() => {
    if (!investmentType) {
      return;
    }

    const amount = parseInt(allocationInvestment, 10);

    if (amount < MIN_INVESTMENT || amount > MAX_INVESTMENT) {
      setEstimatedFees(INITIAL_FEES);
      return;
    }

    getFees(amount, debouncedRisk, investmentType).then((fees) => {
      setEstimatedFees(fees);
    });
  }, [allocationInvestment, debouncedRisk, investmentType]);

  // We don't care about fees.future because we make our own calculations.
  // So we use fees.cost, which is in percentage, like 0.12%
  // fees.rebase is expressed in basis points, so we divide it by 100 to get it
  // in percentage instead
  const fundManagementPercentage = estimatedFees.cost.fundManagement;
  const fundsPercentage = estimatedFees.cost.fundAssets;
  const transactionsPercentage = estimatedFees.cost.transactionFees;

  const simulated = simulateFutureWorthAndFee({
    initialInvestment: parseFloat(allocationInvestment),
    monthlyInvestment: monthly,
    years: horizon,
    expectedYearlyYield: expectedYield,
    yearlyFundFeePercentage: fundsPercentage,
    yearlyTransactionFeePercentage: transactionsPercentage,
    yearlyLysaFundManagementFeePercentage: fundManagementPercentage,
    customerAum: 0,
  });
  const lysaMinPercentage = fundManagementPercentage + simulated.minLysaFee;
  const lysaMaxPercentage = fundManagementPercentage + simulated.maxLysaFee;
  const minTotalPercentage =
    lysaMinPercentage + fundsPercentage + transactionsPercentage;
  const maxTotalPercentage =
    lysaMaxPercentage + fundsPercentage + transactionsPercentage;
  const fundsCost = simulated.totalFees.fundFee;
  const transactionsCost = simulated.totalFees.transactionFee;
  const lysaCost =
    simulated.totalFees.discretionaryFee +
    simulated.totalFees.lysaFundManagementFee;

  return (
    <div className="company-signup-edit-allocation-fees">
      <h4>Exempelmånadsspar</h4>
      <MoneyInput
        currency="SEK"
        value={monthly.toString()}
        onChange={(value) => setMonthly(parseFloat(value) || 0)}
        placeholder="2 000"
      />
      <Slider
        label={
          <h4 className="slider-label">
            <span>Sparhorisont</span> <i>{horizon} år</i>
          </h4>
        }
        min={1}
        max={50}
        value={horizon}
        onChange={(value) => setHorizon(value)}
      />
      <Slider
        label={
          <h4 className="slider-label">
            <span>Förväntad avkastning</span>{" "}
            <i>
              <FormattedPercentage value={expectedYield} decimals={1} />
            </i>
          </h4>
        }
        min={0}
        max={10}
        step={0.1}
        value={expectedYield}
        onChange={(value) => setExpectedYield(value)}
      />
      <h4>Uppskattade avgifter på {horizon} år</h4>
      <dl>
        <dt>
          Lysas avgift{" "}
          <span>
            {lysaMinPercentage === lysaMaxPercentage ? (
              <FormattedPercentage value={lysaMinPercentage} decimals={3} />
            ) : (
              <>
                <FormattedPercentage value={lysaMinPercentage} decimals={3} /> -{" "}
                <FormattedPercentage value={lysaMaxPercentage} decimals={3} />
              </>
            )}
          </span>
        </dt>
        <dd>
          <Amount amount={lysaCost} />
        </dd>
        <dt>
          Fonder <span>{fundsPercentage}%</span>
        </dt>
        <dd>
          <Amount amount={fundsCost} />
        </dd>
        <dt>
          Transaktionsavgifter <span>{transactionsPercentage}%</span>
        </dt>
        <dd>
          <Amount amount={transactionsCost} />
        </dd>
        <dt className="total">
          Totalt{" "}
          <span>
            {minTotalPercentage === maxTotalPercentage ? (
              <FormattedPercentage value={minTotalPercentage} decimals={3} />
            ) : (
              <>
                <FormattedPercentage value={minTotalPercentage} decimals={3} />
                {" - "}
                <FormattedPercentage value={maxTotalPercentage} decimals={3} />
              </>
            )}
          </span>
        </dt>
        <dd className="total">
          <Amount amount={simulated.fee} />
        </dd>
      </dl>
      <div className="fee-disclaimer">
        <p>
          Förhandsberäkningen baseras på dagens faktiska avgifter och kostnader
          och beräknas utifrån din valda målfördelning, din sparhorisont, din
          initiala insättning på kontot, ditt månadsspar, din förväntade
          avkastning samt ditt nuvarande investerade belopp. Beräkningen av den
          totala avgiften tar hänsyn till att Lysas avgift sjunker desto mer du
          sparar, därför visas ibland ett intervall för avgiften i procent.
          Kostnader medför att avkastningen på din investering minskar med
          motsvarande belopp.
        </p>
        <p>
          <span data-elevio-article={FEE_DESCRIPTION_ELEVIO_ARTICLE_ID}>
            Klicka för en förklaring av Lysas avgifter
          </span>
        </p>
      </div>
    </div>
  );
};
