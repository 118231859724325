import { useState, useContext, useEffect } from "react";
import { getAllocation } from "../../../data/market";
import { Spinner, useDebounceValue } from "@lysaab/ui-2";
import { CompanySignupContext } from "../../../models/CompanySignupContextProvider";
import { Fund, FundType, getHoldings } from "../../../data/holdings";
import { PositionsList, Positions } from "../../../components/PositionsList";

import "./EditAllocationPositions.scss";
import { MIN_INVESTMENT, MAX_INVESTMENT } from "./EditAllocation";
import { useAdvice } from "../../../models/AdviceContext";

interface Props {
  chosenAllocation: string;
}

const DEBOUNCE_TIMER = 500;

export const EditAllocationPositions = ({ chosenAllocation }: Props) => {
  const [loading, setLoading] = useState(true);
  const [holdings, setHoldings] = useState<Fund[]>();
  const [positions, setPositions] = useState<Positions>({});
  const risk = parseInt(chosenAllocation, 10);
  const { state: contextState } = useContext(CompanySignupContext);
  const advice = useAdvice();
  const investmentType = advice.result.investmentType;

  let investment = parseInt(contextState.allocationInvestment || "0", 10);
  if (investment < MIN_INVESTMENT || investment > MAX_INVESTMENT) {
    investment = 0;
  }

  useEffect(() => {
    getHoldings().then(setHoldings);
  }, []);

  const debouncedRisk = useDebounceValue(risk, DEBOUNCE_TIMER);

  useEffect(() => {
    getAllocation(debouncedRisk, investmentType).then((allocation) => {
      const pos: Positions = {};
      Object.keys(allocation).forEach((isin) => {
        pos[isin] = { worth: (investment * allocation[isin]) / 100 };
      });
      setPositions(pos);
      setLoading(false);
    });
  }, [debouncedRisk, investmentType, investment]);

  return (
    <div className="company-signup-edit-allocation-positions">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <PositionsList
            header="Aktier"
            holdings={holdings}
            positions={positions}
            type={FundType.STOCKS}
          />
          <PositionsList
            header="Räntor"
            holdings={holdings}
            positions={positions}
            type={FundType.BONDS}
          />
        </>
      )}

      <div className="disclaimer">
        Fördelningen mellan de underliggande innehaven är en bild av fondens
        nuvarande målinnehav. Fondens faktiska innehav kan avvika från
        målfördelningen t ex på grund av marknadsrörelser, insättningar samt
        uttag i Lysas fonder.
      </div>
    </div>
  );
};
