import React, { useState, useContext } from "react";
import {
  ChoiceChipGroup,
  Alternative,
  ReactForecast,
  GetMessageFunctionArgs,
  GetMessageFunction,
  formatNumber,
} from "@lysaab/ui-2";
import { MoneyInput } from "@lysaab/ui-2/components/input/MoneyInput";
import { CompanySignupContext } from "../../../models/CompanySignupContextProvider";
import { MIN_INVESTMENT, MAX_INVESTMENT } from "./EditAllocation";

import "./EditAllocationForecast.scss";

const yearAlternatives = [
  {
    text: "5 år",
    value: "5",
  },
  {
    text: "10 år",
    value: "10",
  },
  {
    text: "15 år",
    value: "15",
  },
  {
    text: "20 år",
    value: "20",
  },
];

const getTexts: GetMessageFunction = (args: GetMessageFunctionArgs) => {
  return {
    tooShort: "För tidsperioder under ett år, kan inte avkastning simuleras.",
    probability: `Bedömd sannolikhet att du om ${args.years} år och ${args.months} månader har`,
    veryGoodText: (
      <>
        <b>Mer</b> än <b>{formatNumber(args.veryGoodSum, 0)}</b> kr är{" "}
        <b>{formatNumber(args.veryGoodPercentage, 2)}</b> %
      </>
    ),
    goodText: (
      <>
        <b>Mer</b> än <b>{formatNumber(args.goodSum, 0)}</b> kr är{" "}
        <b>{formatNumber(args.goodPercentage, 2)}</b> %
      </>
    ),
    badText: (
      <>
        <b>Mindre</b> än <b>{formatNumber(args.badSum, 0)}</b> kr är{" "}
        <b>{formatNumber(args.badPercentage, 2)}</b> %
      </>
    ),
    veryBadText: (
      <>
        <b>Mindre</b> än <b>{formatNumber(args.veryBadSum, 0)}</b> kr är{" "}
        <b>{formatNumber(args.veryGoodPercentage, 2)}</b> %
      </>
    ),
  };
};

interface Props {
  chosenAllocation: string;
}

export const EditAllocationForecast = ({ chosenAllocation }: Props) => {
  const [monthly, setMontly] = useState("2000");
  const [year, setYear] = useState<Alternative<string>>(yearAlternatives[0]);
  const { state: contextState } = useContext(CompanySignupContext);
  let investment = parseInt(contextState.allocationInvestment, 10);

  if (investment < MIN_INVESTMENT || investment > MAX_INVESTMENT) {
    investment = 0;
  }

  return (
    <div className="company-signup-edit-allocation-forecast">
      <h4>Exempelmånadsspar</h4>
      <MoneyInput
        currency="SEK"
        value={monthly}
        onChange={(value) => setMontly(value)}
        placeholder="2 000"
      />
      <h4>Utveckling</h4>
      <ChoiceChipGroup
        onChange={(alternative) => {
          setYear(alternative);
        }}
        value={year}
        alternatives={yearAlternatives}
      />
      <div className={"graph-wrapper" + (investment ? "" : " disabled")}>
        <ReactForecast
          initial={investment.toString()}
          interval={year.value}
          monthly={investment ? monthly : "0"}
          risk={chosenAllocation}
          locale={"sv-SE"}
          currency="SEK"
          getTexts={getTexts}
        />
      </div>
    </div>
  );
};
